import React, { Component } from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import DocumentTitle from 'react-document-title';
import Article from 'components/Article';
import ArticleServiceRequest from 'components/ArticleServiceRequest';
import ArticleTracker from 'components/ArticleTracker';
import Author from 'components/Author';
import LandingPage from 'components/LandingPage';
import Rankings from 'components/Rankings';
import SimpleAppBar from 'components/SimpleAppBar';
import TinyUrl from 'components/TinyUrl';
import 'App.css';

class App extends Component {
  componentDidMount() {
    window.gtag('event', this.props.location.pathname);
  }

  render() {
    const { t } = this.props;
    const { pathname } = this.props.location;
    const defaultTitle = t('site.brandName');

    return (
      <div>
        { pathname === "/" ? null : <SimpleAppBar /> }
        <DocumentTitle title={defaultTitle}>
          <Switch>
            <Route exact path="/" render={props => <LandingPage />} />
            <Route exact path="/articles/:id" render={props => <Article />} />
            <Route exact path="/articles/:id/requests/new" render={props => <ArticleServiceRequest />} />
            <Route path="/authors/:id" render={props => <Author />} />
            <Route path="/p-tracker" render={props => <ArticleTracker />} />
            <Route exact path="/rankings" render={props => <Rankings />} />
            <Route path="/s/:id" render={props => <TinyUrl />} />
          </Switch>
        </DocumentTitle>

        <Typography variant="caption" align="center" color="textSecondary" paragraph>
          © {new Date().getFullYear()} {t('site.companyName')}
        </Typography>
      </div>
    );
  }
}

export default withNamespaces()(withRouter(App));
