import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Linkify from 'react-linkify';
import AdSense from 'react-adsense';

const styles = theme => ({
  imgPreview: {
    maxHeight: "450px",
    maxWidth: "100%",
  },
  listItem: {
    padding: '3px 0 0 0'
  },
  listItemText: {
    fontSize: theme.typography.pxToRem(14),
    paddingRight: '0px',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-all',
  },
  listNested: {
    fontSize: theme.typography.pxToRem(14),
    paddingLeft: '0px',
    paddingRight: '0px',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap',
  },
  pushAvatar: {
    color: '#f1f1f1',
    fontSize: theme.typography.pxToRem(16),
    height: '30px',
    width: '30px',
  },
  pushPlusOne: {
    backgroundColor: '#8bc34a',
  },
  pushMinusOne: {
    backgroundColor: '#a5310c',
  },
});

class Pushes extends Component {
  renderGoogleAds() {
    if (!this.props.showAds) return null;

    return (
      <Card>
        <AdSense.Google
          client='ca-pub-1979225000568755'
          slot='2432691649'
          format='auto'
          responsive='true'
        />
      </Card>
    );
  }

  insertImagePreview(text) {
    const { classes } = this.props;
    const urlMatches = text.match(/\b(http|https)?:\/\/(i\.)?imgur\.com\/\S+/gi) || [];

    if (urlMatches.length === 0) { return null }

    return (
      urlMatches.map((link) => {
        const destLink = link.includes('://imgur.com/') ? link.replace(/:\/\/imgur\.com/, '://i.imgur.com') + '.jpg' : link;

        return (
          <ListItem className={classes.listNested} key={destLink}>
            <img className={classes.imgPreview} src={destLink} alt={destLink} />
          </ListItem>
        );
      })
    );
  }

  render() {
    const { username, pushes, classes, showAds } = this.props;
    const count = pushes.length;

    return (
      <List dense={true}>
        {pushes && count > 0 &&
          pushes.map((push, index) => {
            const pushHeader = push.score === 1 ? "推" : push.score === 0 ? "→" : "噓";
            const pushHeaderClass = push.score === 1 ? classes.pushPlusOne : push.score !== 0 ? classes.pushMinusOne : null
            const pushedAt = new Date(push.pushed_at);
            const pushedAtFormatOptions = {
              year: 'numeric', month: 'numeric', day: 'numeric',
              hour: 'numeric', minute: 'numeric',
              hour12: false,
              timeZone: 'Asia/Taipei' 
            };
            const pushedAtString = new Intl.DateTimeFormat('zh-TW', pushedAtFormatOptions).format(pushedAt);

            return (
              <div key={push.id}>
                <ListItem className={classes.listItem} key={push.id}>
                  <Avatar className={[pushHeaderClass, classes.pushAvatar].join(' ')}>
                    {pushHeader}
                  </Avatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        {
                          !!push.author && !!push.author.username ?
                          <Link
                            className={classes.listItemText}
                            to={`/authors/${push.author.username.toLowerCase()}`}
                          >
                            {push.author.username}
                          </Link> : username || 'unknown user'
                        }
                        <Linkify className={classes.listItemText}>{push.content}</Linkify>
                      </React.Fragment>
                    }
                    secondary={pushedAtString}
                    className={classes.listItemText}
                  />
                </ListItem>

                { this.insertImagePreview(push.content) }

                {
                  !!push.reply ?
                  <List disablePadding dense={true}>
                    <ListItem className={classes.listNested}>
                      <ListItemText primary={push.reply} className={classes.listItemText}/>
                    </ListItem>
                  </List> :
                  null
                }

                {
                  showAds && index % 20 === 0 ?
                  <List disablePadding dense={true}>
                    <ListItem className={classes.listNested}>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            {this.renderGoogleAds()}
                          </React.Fragment>
                        }
                        className={classes.listItemText}
                        secondary="sponsored message"
                      />
                    </ListItem>
                  </List> : null
                }
              </div>
            );
          })
        }
      </List>
    );
  }
}

Pushes.propTypes = {
  classes: PropTypes.object.isRequired,
  pushes: PropTypes.array.isRequired,
  showAds: PropTypes.bool,
  username: PropTypes.string,
};

export default withStyles(styles)(Pushes);
