import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Loading from 'components/Loading';

class TinyUrl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      targetUrl: null
    }
  }

  componentDidMount() {
    this.getTinyUrl(); 
  }

  getTinyUrl() {
    const { id } = this.props.match.params;

    axios.get('/api/v1/tiny_urls/' + id)
      .then(response => {
        this.setState({
          isLoading: false,
          targetUrl: response.data.url
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const { isLoading, targetUrl } = this.state;

    if (isLoading) {
      return (
        <div>
          <Loading />
          <Typography variant="h6" align="center" color="textPrimary" gutterBottom>
            Redirecting...
          </Typography>
        </div>
      );
    }

    if (targetUrl) {
      return (<Redirect to={this.state.targetUrl} />);
    } else {
      return (<Redirect to="/" />);
    }
  }
}

export default withRouter(TinyUrl);
