import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import Loading from 'components/Loading';

const styles = theme => ({
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit,
  },
  container: {
    padding: theme.spacing.unit * 2,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  textField: {
    marginTop: theme.spacing.unit * 3,
  },
});

function getSteps() {
  return ['Before You Start', 'Check Article Status', 'Verify Ownership'];
}

function getStepContent(step) {
  switch(step) {
    case 0:
      return `Make sure you are the author of the article and the
      article has been deleted from the origin`;
    case 1:
      return `Let's start with checking the origin article status`;
    case 2:
      return `Add the validation code below to your profile by visiting
      the origin and navigating to (U)ser -> (M)y Files -> (Q)ueryEdit.
      We will verify your request within 15 business days.
      Once verified, the article will become inaccessible on Plytic`;
    default:
      return `Unknown step`;
  }
}

function getActionButtonLabels(step) {
  switch(step) {
    case 0:
      return { back: 'Back', next: 'Next'};
    case 1:
      return { back: 'Back', next: 'Check'};
    case 2:
      return { back: 'Back', next: 'Finish'};
    default:
      return { back: 'Back', next: 'Next'};
  }
}

class ArticleServiceRequest extends Component {
  state = {
    activeStep: 0,
    article: null,
    processing: false,
    code: null,
    errorMessage: null,
    loading: true
  };

  componentDidMount() {
    const { id } = this.props.match.params;

    axios
      .get('/api/v1/articles/' + id)
      .then(response => {
        this.setState({
          article: response.data,
          loading: false
        });
      }).catch(error => {
        console.log(error);

        this.setState({
          loading: false
        });
      });
  }

  handleNext = () => {
    const { activeStep, article } = this.state;

    if (activeStep === 1) {
      this.setState({
        processing: true
      });

      axios
        .post('/api/v1/articles/' + article.id + '/service_requests')
        .then(response => {
          this.setState({
            activeStep: activeStep + 1,
            code: response.data.code,
            processing: false
          });
        }).catch(error => {
          const response = error.response

          this.setState({
            errorMessage: response.data.error_message,
            processing: false
          });
        });
    } else {
      this.setState({
        activeStep: activeStep + 1
      });
    }
  }

  handleBack = () => {
    const { activeStep } = this.state;

    this.setState({
      activeStep: activeStep - 1,
      code: null,
      errorMessage: null
    });
  }

  handleRequest = () => {
    const { article } = this.state;

    this.setState({ loading: true });

    axios.post('/api/v1/articles/' + article.id + '/service_requests')
      .then(response => {
        const { code } = response.data;

        this.setState({
          code: code,
          loading: false
        });
      })
      .catch(error => {
        console.log(error);

        this.setState({
          code: null,
          errorMessage: 'Something went wrong',
          loading: false
        });
      });
  }

  render() {
    const { article, loading } = this.state;

    if (loading) { return <Loading /> }
    if (!article) { return <Redirect to='/' /> }

    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep, code, errorMessage, processing } = this.state;

    return (
      <Grid container className={classes.container}>
        <Grid item lg={4} md={3} sm={2} />
        <Grid item lg={4} md={6} sm={8} xs={12}>
          <Paper className={classes.paper} square={true}>
            <Typography variant="h6" gutterBottom>Remove Article from Plytic UI</Typography>
            <Typography>Board: {article.board.name}</Typography>
            <Typography>Author: {article.author.username}</Typography>
            <Typography>Title: {article.title}</Typography>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel error={index === activeStep && !!errorMessage}>{label}</StepLabel>
                  <StepContent>
                    <Typography gutterBottom>{getStepContent(index)}</Typography>
                    {errorMessage && index === activeStep &&
                      <Typography gutterBottom color="error">{errorMessage}</Typography>
                    }
                    {code && index === activeStep &&
                      <TextField
                        id="validation-code-read-only-input"
                        label="Validation Code"
                        defaultValue={code}
                        className={classes.textField}
                        maring="normal"
                        InputProps={{
                          readOnly: true
                        }}
                        variant="outlined"
                      />
                    }
                    <div className={classes.actionsContainer}>
                      <div>
                        <Button
                          disabled={activeStep === 0 || processing}
                          onClick={this.handleBack}
                          className={classes.button}
                        >
                          Back
                        </Button>
                        <Button
                          disabled={!!errorMessage || processing}
                          variant="contained"
                          color="primary"
                          onClick={this.handleNext}
                          className={classes.button}
                        >
                          {processing ? 'Checking...' : getActionButtonLabels(index).next}
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

ArticleServiceRequest.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(ArticleServiceRequest));
