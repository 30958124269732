import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withNamespaces } from 'react-i18next';

const styles = theme => ({
  button: {
    marginTop: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit,
  },
  chip: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
  },
  container: {
    padding: theme.spacing.unit * 2,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

class ArticleTracker extends Component {

  render() {
    const { classes, t } = this.props;

    return (
      <Grid container className={classes.container}>
        <Grid item lg={4} md={3} sm={2} />
        <Grid item lg={4} md={6} sm={8} xs={12}>
          <Paper className={classes.paper} square={true}>
            <Typography variant="h5" gutterBottom>
              {t('articleTracker.hero.heading')}
            </Typography>
            <Chip label="BETA" className={classes.chip} color="secondary" />
            <Typography variant="caption" color="textSecondary" gutterBottom>
              {t('articleTracker.hero.content1')}
            </Typography>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              {t('articleTracker.hero.content2')}
            </Typography>
            <Button
              variant="contained"
              href="https://www.facebook.com/plytic"
              color="primary"
              className={classes.button}
            >
              {t('articleTracker.go')}
            </Button>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

ArticleTracker.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withNamespaces()(ArticleTracker));
