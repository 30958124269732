import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { withNamespaces } from 'react-i18next';
import RankingList from 'components/RankingList';

const styles = theme => ({
  container: {
    textAlign: 'left',
  },
});

class Rankings extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid container className={classes.container}>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <RankingList category='most_supportive_pushers' timeFrame='7' />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <RankingList category='most_critical_pushers' timeFrame='7' />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <RankingList category='number_of_pushes' timeFrame='7' />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <RankingList category='number_of_articles' timeFrame='7' />
          </Grid>
        </Grid>
        <Grid container className={classes.container}>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <RankingList category='most_supportive_pushers' timeFrame='30' />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <RankingList category='most_critical_pushers' timeFrame='30' />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <RankingList category='number_of_pushes' timeFrame='30' />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <RankingList category='number_of_articles' timeFrame='30' />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(withNamespaces()(Rankings));
