import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  heroContent: {
    width: "100%",
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 15px ${theme.spacing.unit * 6}px`,
  },
  loader: {
    border: "8px solid #f3f3f3",
    borderTop: "8px solid #3f51b5",
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    animation: "loading-spin 2s linear infinite",
    margin: "auto",
  },
  main: {
    width: "100%",
  },
});

class Loading extends Component {
  render() {
    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <div className={classes.heroContent}>
          <div className={classes.loader} />
        </div>
      </main>
    );
  }
}

export default withStyles(styles)(Loading);
