import { combineReducers } from 'redux';
import app from 'reducers/app';
import author from 'reducers/author';

const rootReducer = combineReducers({
  app,
  author,
});

export default rootReducer;
