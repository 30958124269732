import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withNamespaces } from 'react-i18next';
import DocumentTitle from 'react-document-title';
import IntegrationAutosuggest from 'components/IntegrationAutosuggest';
import Activities from 'components/Activities';
import SourceIps from 'components/SourceIps';
import RelationshipCloud from 'components/RelationshipCloud';

const styles = theme => ({
  container: {
    padding: 15,
    textAlign: 'left',
  },
  tabs: {
    marginBottom: 10,
  },
});

class Author extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 0,
    };
  }

  componentDidMount() {
    this.setCurrentTab();
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    const { pathname } = this.props.location;

    if (id !== prevProps.match.params.id ||
        pathname !== prevProps.location.pathname) {
      this.setCurrentTab();
    }
  }

  handleTabChange = (event, value) => {
    this.setState({ currentTab: value });
  }

  setCurrentTab() {
    const { pathname } = this.props.location;

    if (/sourceips$/.test(pathname)) {
      this.setState({ currentTab: 1 });
    } else if (/reCloud$/.test(pathname)) {
      this.setState({ currentTab: 2 });
    } else {
      this.setState({ currentTab: 0 });
    }
  }

  render() {
    const { classes, match, t } = this.props;
    const { currentTab } = this.state;
    const { id } = match.params;
    const title = id + ' | ' + t('site.brandName');

    return (
      <DocumentTitle title={title}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <IntegrationAutosuggest
              defaultPopper={id}
              label={t('app.integrationAutosuggest.label')} />
            <Tabs
              className={classes.tabs}
              textColor="primary"
              value={currentTab}
              onChange={this.handleTabChange}>
              <Tab
                label={t('author.tabs.activities')}
                component={Link}
                to={`${match.url}/activities`}
              />
              <Tab
                label={t('author.tabs.ipList')}
                component={Link}
                to={`${match.url}/sourceips`}
              />
              <Tab
                label={t('author.tabs.relationshipCloud')}
                component={Link}
                to={`${match.url}/reCloud`}
              />
            </Tabs>
            <Switch>
              <Route
                exact
                path={`${match.path}`}
                render={props => <Activities {...props} />}
              />
              <Route
                path={`${match.path}/activities`}
                render={props => <Activities {...props} />}
              />
              <Route
                path={`${match.path}/sourceips`}
                render={props => <SourceIps {...props} />}
              />
              <Route
                path={`${match.path}/reCloud`}
                render={props => <RelationshipCloud {...props} />}
              />
            </Switch>
          </Grid>
        </Grid>
      </DocumentTitle>
    );
  }
}

Author.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withNamespaces()(withRouter(Author)));
