import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactWordcloud from 'react-wordcloud';
import axios from 'axios';
import Loading from 'components/Loading';

class RelationshipCloud extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      ties: [],
    };
  }

  componentDidMount() {
    this.getTies();
  }

  getTies() {
    const { id } = this.props.match.params;
    axios.get('/api/v1/authors/' + id + '/ties')
      .then(response => {
        this.setState({
          isLoading: false,
          ties: response.data,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const { isLoading } = this.state;
    const { history } = this.props;

    if (isLoading) { return <Loading />; }

    const { ties } = this.state;
    const callbacks = {
      onWordClick: (word) => {
        history.push('/authors/' + word.username.toLowerCase());
      }
    };
    const options = {
      fontSizes: [8, 40],
      rotations: 1,
      rotationAngles: [0],
    };

    return (
      <ReactWordcloud
        callbacks={callbacks}
        options={options}
        words={ties}
      />
    );
  }
}

RelationshipCloud.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(RelationshipCloud);
