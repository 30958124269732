import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { withNamespaces } from 'react-i18next';
import history from 'CustomHistory';
import Loading from 'components/Loading';

const styles = theme => ({
  chip: {
    marginRight: "15px",
    minWidth: "40px",
  },
  link: {
    textDecoration: "none",
  },
  main: {
    minHeight: "200px",
    padding: 5,
  },
  score: {
    paddingRight: 10,
  }
});

class RankingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      titleLocaleKey: null,
      timeFrame: null,
      items: []
    };
  }

  componentDidMount() {
    const { category, timeFrame } = this.props;

    axios.get('/api/v1/rankings?category=' + category + '&time_frame=' + timeFrame)
      .then(response => {
        const json = response.data;
        const titleLocaleKey = json === null ? '' : 'rankingList.title.' + json.category;
        const items = json === null ? [] : json.ranking;

        this.setState({
          isLoaded: true,
          titleLocaleKey: titleLocaleKey,
          timeFrame: json.time_frame,
          items: items,
        });
      })
      .catch(error => {
        this.setState({
          isLoaded: true,
          error,
        });
        console.log(error);
      });
  }

  handleListClick = (param) => (e) => {
    e.preventDefault();
    history.push('/authors/' + param.toLowerCase());
  }

  renderList() {
    const { error, isLoaded, items, timeFrame, titleLocaleKey } = this.state;
    const { classes, t } = this.props;

    if (error) {
      return <Typography variant="caption">{error.message}</Typography>;
    } else if (!isLoaded) {
      return <Loading />;
    } else {
      return (
        <div className={classes.main}>
          <Typography className={classes.title} align="center" variant="h6" gutterBottom>
            {t(titleLocaleKey, { timeFrame: timeFrame })}
          </Typography>
          <List dense component="nav">
            {items.map((item, index) => (
              <Link to={`/authors/${item.author.username}`} key={item.id} className={classes.link}>
                <ListItem button onClick={this.handleListClick(item.author.username)}>
                  <Chip label={item.rank + 1} className={classes.chip} />
                  <ListItemText primary={item.author.username} />
                  <ListItemSecondaryAction>
                    <Typography className={classes.score} variant="body1">
                      {item.score}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              </Link>
            ))}
          </List>
        </div>
      );
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.main}>
        {this.renderList()}
      </div>
    );
  }
}

export default withStyles(styles)(withNamespaces()(RankingList));
