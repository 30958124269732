import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CalendarHeatmap from 'react-calendar-heatmap';
import ReactTooltip from 'react-tooltip';

class Heatmap extends Component {
  render() {
    const { handleClick, heatmap } = this.props;
    const monthLabels = Array.from(Array(12), (_,x) => x+1);

    return (
      <div>
        <CalendarHeatmap
          startDate={heatmap.startDate}
          endDate={heatmap.endDate}
          values={heatmap.entries}
          monthLabels={monthLabels}
          classForValue={(value) => {
            if (!value) { return 'color-empty-custom' };
            const scale = Math.min(Math.floor(value.count/5), 8);
            return `color-scale-${scale}`;
          }}
          tooltipDataAttrs={(value) => {
            if (!value) { return };
            return {'data-tip': value.date}
          }}
          onClick={(value) => {
            if (!value) { return };
            let d = new Date(value.date + "T16:00:00+0000")
            d.setDate(d.getDate() - 1);
            handleClick({
              per_page: 5,
              start_time: d.valueOf()/1000,
              end_time: (+ new Date(value.date + "T16:00:00+0000"))/1000,
            });
          }}
        />
        <ReactTooltip />
      </div>
    );
  }
}

Heatmap.propTypes = {
  handleClick: PropTypes.func.isRequired,
  heatmap: PropTypes.object.isRequired,
};

export default Heatmap;
