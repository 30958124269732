import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from "react-router-dom";
import configureStore from 'store/configureStore';
import 'index.css';
import App from 'App';
import 'typeface-roboto';
import WebFont from 'webfontloader';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { I18nextProvider } from 'react-i18next';
import i18n from 'locales';
import history from 'CustomHistory';

const initialState = {
  app: {
    isFetching: false
  }
};

const store = configureStore(initialState);

WebFont.load({
  google: {
    families: ['Noto Sans TC', 'sans-serif']
  }
});

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
  typography: {
    useNextVariants: true,
    fontFamily: "'Noto Sans TC', sans-serif",
  },
  link: {
    color: '#8591d2',
  },
});

history.listen(location => window.gtag('event', location.pathname));

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <I18nextProvider i18n={i18n}>
        <Router history={history}>
          <App />
        </Router>
      </I18nextProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
);
