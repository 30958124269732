import Actions from 'constants/actions';

export default function appStatusReducer(state = {}, action) {
  switch(action.type) {
    case Actions.Api.REQUESTED:
      return Object.assign({}, state, { "isFetching": true });
    case Actions.Api.RECEIVED:
      return Object.assign({}, state, { "isFetching": false });
    default:
      return state;
  }
}
