import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withNamespaces } from 'react-i18next';
import AdSense from 'react-adsense';
import Pushes from 'components/Pushes';

const styles = theme => ({
  articleDigest: {
    fontSize: theme.typography.pxToRem(14),
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-all',
  },
  card: {
    marginBottom: 10,
    '& a': theme.link,
  },
  chip: {
    marginRight: 10,
  },
  postedAt: {
    marginBottom: 12,
  },
});

class Activity extends Component {
  render() {
    const { activity, classes, displayAd, t, username } = this.props;
    const postedAt = new Date(activity.posted_at);
    const postedAtFormatOptions = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: false,
      timeZone: 'Asia/Taipei'
    };
    const postedAtString = new Intl.DateTimeFormat('zh-TW', postedAtFormatOptions).format(postedAt);

    return (
      <Grid item xs={12} key={activity.id}>
        { displayAd ?
          <Card className={classes.card}>
            <AdSense.Google
              client='ca-pub-1979225000568755'
              slot='3735810001'
              format='auto'
              responsive='true'
            />
          </Card> : null }
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              <Chip
                color={activity.author.username === username ? "primary" : "default"}
                label={activity.board.name}
                className={classes.chip} />
              <Chip
                label={activity.score > 0 ? "+" + activity.score : activity.score}
                className={classes.chip} />
              {activity.title}
            </Typography>
            <Typography className={classes.postedAt} color="textSecondary">
              作者:&nbsp;
              <Link
                to={`/authors/${activity.author.username.toLowerCase()}`}>
                {activity.author.username}
              </Link>
              &nbsp;- 發表於{postedAtString}
            </Typography>
            <Typography className={classes.articleDigest} variant="body2" gutterBottom>
              {activity.private ? '-- CONTENT NOT AVAILABLE --' : activity.digest + '...... '}
              {activity.private ? null : <Link to={`/articles/${activity.guid.toLowerCase().replace(/\./g, '-')}`}>{t('activity.readMore')}</Link>}
            </Typography>
            {
              !activity.private && activity.pushes && activity.pushes.length > 0 ?
              <div>
                <Divider component="hr" />
                <Pushes pushes={activity.pushes} username={username} />
              </div>:
              null
            }
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

Activity.propTypes = {
  activity: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
};

export default withStyles(styles)(withNamespaces()(Activity));
