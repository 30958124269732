import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  heroContent: {
    width: "100%",
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 15px ${theme.spacing.unit * 6}px`,
  },
  main: {
    width: "100%",
  },
});

class ErrorDisplay extends Component {
  render() {
    const { classes, text } = this.props;

    return (
      <main className={classes.main}>
        <div className={classes.heroContent}>
          <Typography variant="caption" color="textSecondary" gutterBottom>
            {text}
          </Typography>
        </div>
      </main>
    );
  }
}

export default withStyles(styles)(ErrorDisplay);
