export default {
  Api: {
    RECEIVED: 'API_RECEIVED',
    REQUESTED: 'API_REQUESETED',
  },
  Author: {
    CLEAR: 'CURRENT_AUTHOR_CLEAR',
    UPDATE: 'CURRENT_AUTHOR_UPDATE',
  }
};
