import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import { withNamespaces } from 'react-i18next';
import Loading from 'components/Loading';

const styles = theme => ({
  divider: {
    marginBottom: 15,
    marginTop: 15,
  },
  sourceIp: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
  },
  sourceIpChips: {
    marginRight: 5,
    marginBottom: 5,
  },
  sourceIpHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '50%',
    flexShrink: 0,
  },
  sourceIpSecondaryHeading: {
    color: theme.palette.text.secondary,
  },
  sourceIpSecondaryHeadingButton: {
    fontSize: theme.typography.pxToRem(8),
    padding: '0 8px',
    margin: theme.spacing.unit,
  },
  sourceIpSummaryContent: {
    justifyContent: 'space-between',
  },
});

class SourceIps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    }
  }

  componentDidMount() {
    this.getSourceIps();
  }

  getSourceIps() {
    const { id } = this.props.match.params;

    axios.get('/api/v1/authors/' + id + '/source_ips')
      .then(response => {
        this.setState({
          sourceIps: response.data,
          isLoading: false,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  renderRecentSourceIp(entry) {
    const { classes } = this.props;
    const lastSeenAt = new Date(entry.last_seen_at);
    const lastSeenAtFormatOptions = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour12: false, timeZone: 'Asia/Taipei'
    };
    const lastSeenAtString = new Intl.DateTimeFormat('zh-TW', lastSeenAtFormatOptions).format(lastSeenAt);
    const chipLabel = entry.ip + ' - ' + lastSeenAtString;
    const chipColor = entry.country === 'TW' ? 'primary' : entry.country === null ? 'default' : 'secondary';

    return (
      <Chip
        key={entry.ip}
        avatar={<Avatar>{entry.country || '?'}</Avatar>}
        label={chipLabel}
        className={classes.sourceIpChips}
        color={chipColor}
      />
    );
  }

  renderSharedIpsWith(entry) {
    const { classes, t } = this.props;

    return (
      <ExpansionPanel key={entry.author.id}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.sourceIpSummaryContent }}>
          <Typography className={classes.sourceIpHeading}>
            {t("sourceIp.shareWith", { count: entry.ips.length, username: entry.author.username })}
          </Typography>
          <Typography className={classes.sourceIpSecondaryHeading}>
            <Button
              component={({...props}) => <Link to={`/authors/${entry.author.username.toLowerCase()}`} {...props} />}
              variant="outlined"
              className={classes.sourceIpSecondaryHeadingButton}
            >
              {t("sourceIp.viewAuthor")}
            </Button>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.sourceIp}>
          {entry.ips.map((ip) => <Chip label={ip} key={ip+entry.author.id} className={classes.sourceIpChips} />)}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  render() {
    const { classes } = this.props;
    const { isLoading, sourceIps } = this.state;

    if (isLoading) { return <Loading /> }

    const recentSourceIps = sourceIps.recent_source_ips.map((sourceIp) => this.renderRecentSourceIp(sourceIp));
    const sharedIpsWith = sourceIps.shared_ips_with.map((sourceIp) => this.renderSharedIpsWith(sourceIp));

    return (
      <div>
        <Grid item xs={12} className={classes.summaryGrid}>
          {recentSourceIps}
        </Grid>
        <Divider variant="middle" className={classes.divider} />
        <Grid item xs={12}>
          {sharedIpsWith}
        </Grid>
      </div>
    );
  }
}

SourceIps.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withNamespaces()(SourceIps));
