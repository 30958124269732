import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withNamespaces } from 'react-i18next';
import axios from 'axios';
import Linkify from 'react-linkify';
import AdSense from 'react-adsense';
import DocumentTitle from 'react-document-title';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from 'components/Loading';
import Pushes from 'components/Pushes';

const styles = theme => ({
  articleDigest: {
    fontSize: theme.typography.pxToRem(14),
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap'
  },
  card: {
    marginBottom: theme.spacing.unit,
    '& a': theme.link,
  },
  chip: {
    marginRight: theme.spacing.unit,
  },
  container: {
    padding: theme.spacing.unit * 2,
    textAlign: 'left',
  },
  imgPreview: {
    maxWidth: "100%",
  },
  infiniteScroll: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  postedAt: {
    marginBottom: theme.spacing.unit,
  },
});

class Article extends Component {
  constructor(props) {
    super(props);

    this.state = {
      article: null,
      currentPage: 0,
      errors: null,
      hasMore: true,
      isLoading: true,
      perPage: 100,
      pushes: [],
      showAds: true
    };
  }

  componentDidMount() {
    this.getArticle();
    this.getPushes();
  }

  getArticle() {
    const { id } = this.props.match.params;

    axios.get('/api/v1/articles/' + id)
      .then(response => {
        const article = response.data;

        this.setState({
          article: article,
          errors: null,
          isLoading: false,
          showAds: !(article.board.restricted_content || article.restricted_content)
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          errors: error,
          isLoading: false,
        });
      });
  }

  getPushes = () => {
    const { id } = this.props.match.params;
    const { currentPage, perPage, pushes } = this.state;
    const nextPage = currentPage + 1;

    axios.get('/api/v1/articles/' + id + '/pushes?page=' + nextPage + '&per_page=' + perPage)
      .then(response => {
        const { current_page, per_page, total_count } = response.data;
        const hasMore = current_page * per_page < total_count ? true : false;

        this.setState({
          pushes: pushes.concat(response.data.pushes),
          currentPage: nextPage,
          hasMore: hasMore
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  insertPreview(text) {
    const { classes } = this.props;

    let currentIndex = 0;
    const urlMatches = text.match(/\b(http|https)?:\/\/(i\.)?imgur\.com\/\S+/gi) || [];
    const splits = urlMatches.map((link) => {
      const startIndex = text.substr(currentIndex).indexOf(link) + currentIndex;
      const endIndex = startIndex + link.length;
      const leadingText = text.substr(currentIndex, endIndex - currentIndex);

      const destLink = link.includes('://imgur.com/') ? link.replace(/:\/\/imgur\.com/, '://i.imgur.com') + '.jpg' : link;

      currentIndex = endIndex + 1;

      return ({ link: destLink, text: leadingText });
    });

    splits.push({ link: null, text: text.substr(currentIndex) });

    return (
      <Linkify>
        {
          splits.map((element) => {
            return (
              <Grid item xs={12} key={element.link}>
                <Typography className={classes.articleDigest} variant="body2" gutterBottom>
                  {element.text}
                </Typography>
                {element.link ?
                  <Grid item xs={12} sm={9} md={6}>
                    <img className={classes.imgPreview} src={element.link} alt={element.link} />
                  </Grid> : null}
              </Grid>
            );
          })
        }
      </Linkify>
    );
  }

  renderGoogleAds() {
    const { classes } = this.props;
    const { showAds } = this.state;

    if (!showAds) return;

    return (
      <Card className={classes.card}>
        <AdSense.Google
          client='ca-pub-1979225000568755'
          slot='9042589887'
          format='auto'
          responsive='true'
        />
      </Card>
    );
  }

  renderArticle() {
    const { classes, match } = this.props;
    const { article, pushes, showAds } = this.state;
    const postedAt = new Date(article.posted_at);
    const postedAtFormatOptions = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: false,
      timeZone: 'Asia/Taipei'
    };
    const postedAtString = new Intl.DateTimeFormat('zh-TW', postedAtFormatOptions).format(postedAt);

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                <Chip
                  color="primary"
                  label={article.board.name}
                  className={classes.chip} />
                <Chip
                  label={article.score > 0 ? "+" + article.score : article.score}
                  className={classes.chip} />
                {article.title}
              </Typography>

              <Typography className={classes.postedAt} color="textSecondary">
                作者:&nbsp;
                <Link
                  to={`/authors/${article.author.username.toLowerCase()}`}>
                  {article.author.username}
                </Link>
                &nbsp;- 發表於{postedAtString}
              </Typography>
            </CardContent>
          </Card>

          <Card className={classes.card}>
            <CardContent>
              {this.renderGoogleAds()}
              {this.insertPreview(article.digest)}
              <Link to={`/articles/${match.params.id}/requests/new`}>Remove Article from Plytic UI</Link>
            </CardContent>
          </Card>

          <Card className={classes.card}>
            <InfiniteScroll
              dataLength={pushes.length}
              next={this.getPushes}
              hasMore={this.state.hasMore}
              className={classes.infiniteScroll}
            >
              { pushes && pushes.length > 0 && <Pushes pushes={pushes} showAds={showAds} /> }
            </InfiniteScroll>
          </Card>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { t } = this.props;
    const { article, errors, isLoading } = this.state;

    if (isLoading) { return <Loading />; }
    if (errors) { return <Redirect to='/' /> }

    const title = article.title + ' | ' + t('site.brandName');

    return (
      <DocumentTitle title={title}>
        {this.renderArticle()}
      </DocumentTitle>
    );
  }
}

Article.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withNamespaces()(withRouter(Article)));
