import Actions from 'constants/actions';

export default function currentAuthorReducer(state = {}, action) {
  switch(action.type) {
    case Actions.Author.CLEAR:
      return Object.assign({}, state, { "activities": [] });
    case Actions.Author.UPDATE:
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
}
