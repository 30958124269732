import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import Snackbar from '@material-ui/core/Snackbar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withNamespaces } from 'react-i18next';
import axios from 'axios';
import history from 'CustomHistory';

const styles = theme => ({
  brandName: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  menuButton: {
    margin: '0 0 0 8px',
    padding: '0 2px 0 2px'
  },
  root: {
    flexGrow: 1,
  },
  spacer: {
    flexGrow: 1,
  }
});

class SimpleAppBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentQ: null,
      snackbarText: null,
      tiny: null
    };
  }

  copyToClipboard(text) {
    var dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.setAttribute('value', text);
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    this.setState({ snackbarText: text + ' 已複製!' });
  }

  getTinyAndCopy = () => {
    const { currentQ, tiny } = this.state;
    const { pathname, search } = window.location;
    const origin = window.location.origin.replace(/^https:\/\/www\./gi, 'http://');
    const q = pathname + search;

    if (currentQ !== q || !tiny) {
      axios.get('/api/v1/tiny_urls?q=' + q)
        .then(response => {
          if (response.data.length > 0) {
            const short = response.data[0].short;

            this.setState({
              currentQ: q,
              tiny: short
            });
            this.copyToClipboard(origin + short);
          }
        }).catch(error => {
          console.log(error);
          this.copyToClipboard(origin + pathname + search);
        });
    } else {
      this.copyToClipboard(origin + tiny);
    }
  }

  handleBrandNameClick = (e) =>  {
    e.preventDefault();
    history.push('/');
  }

  renderSnackbar() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!this.state.snackbarText}
        autoHideDuration={3000}
        onClose={() => this.setState({ snackbarText: null })}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id='message-id'>{this.state.snackbarText}</span>}
      />
    );
  }

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Toolbar>
            <Link to="/" className={classes.brandName}>
              <Typography
                variant="h6"
                onClick={this.handleBrandNameClick}>
                {t('site.brandName')}
              </Typography>
            </Link>
            <div className={classes.spacer} />
            <Typography variant="subtitle2">
              {t('simpleAppBar.getShortUrl')}
            </Typography>
            <IconButton
              aria-label='Get share url'
              className={classes.menuButton}
              onClick={this.getTinyAndCopy}
            >
              <Badge color="secondary" variant="dot">
                <LinkIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        {this.renderSnackbar()}
      </div>
    );
  }
}

SimpleAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withNamespaces()(SimpleAppBar));
