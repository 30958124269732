import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(XHR).use(LanguageDetector).init({
  debug: process.env.NODE_ENV === 'development',
  load: 'currentOnly',
  fallbackLng: 'en-US',
  react: {
    wait: true
  }
});

export default i18n;
