import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';
import history from 'CustomHistory';

const renderInputComponent = (inputProps) => {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
};

const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const matches = match(suggestion.username, query);
  const parts = parse(suggestion.username, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      {parts.map((part, index) => {
        return part.highlight ? (
          <span key={String(index)} style={{ fontWeight: 700 }}>
            {part.text}
          </span>
        ) : (
          <span key={String(index)} style={{ fontWeight: 300, color: '#a2a2a2' }}>
            {part.text}
          </span>
        );
      })}
    </MenuItem>
  );
};

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

class IntegrationAutosuggest extends React.Component {
  constructor() {
    super();

    this.state = {
      single: '',
      popper: '',
      suggestions: [],
      _source: null,
    };
  }

  componentDidMount() {
    const defaultPopper = this.props.defaultPopper;

    if (defaultPopper && defaultPopper.length > 0) {
      this.setState({
        popper: defaultPopper,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const defaultPopper = this.props.defaultPopper;

    if (defaultPopper !== prevProps.defaultPopper) {
      this.setState({
        popper: defaultPopper,
      });
    }
  }

  getSuggestionValue = suggestion => '' + suggestion.username;
  handleSuggestionsClearRequested = () => this.setState({ suggestions: [] });
  handleChange = name => (event, { newValue, method }) => this.setState({ [name]: newValue });

  handleSuggestionsFetchRequested = ({ value }) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    const _source = this.state._source;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    if (_source !== null) { _source.cancel('Request canceled due to new request.') }

    this.setState({ _source: source });

    return inputLength === 0 ? [] :
      axios.get('/api/v1/authors?q=' + inputValue + '&per_page=5', {
        cancelToken: source.token
      })
      .then(response => this.setState({ suggestions: response.data }))
      .catch(error => {
        if (axios.isCancel(error)) {
          // Request canceled. Do nothing...
        } else {
          console.log(error);
        }
      });
  };

  handleKeyDown = (event) => {
    const suggestions = this.state.suggestions;

    if (!(suggestions.length > 0)) { return; }

    const firstUsername = suggestions[0].username.toLowerCase();

    if (event.keyCode === 13 && this.state.popper.toLowerCase() === firstUsername) {
      history.push('/authors/' + firstUsername);
    }
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    history.push('/authors/' + suggestion.username.toLowerCase());
  };

  render() {
    const { classes, label, placeholder } = this.props;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this.getSuggestionValue,
      renderSuggestion,
      onSuggestionSelected: this.onSuggestionSelected,
    };

    const autosuggestInputProps = {
      classes,
      label: label,
      placeholder: placeholder,
      value: this.state.popper,
      onChange: this.handleChange('popper'),
      onKeyDown: this.handleKeyDown,
      inputRef: node => {
        this.popperNode = node;
      },
      InputLabelProps: {
        shrink: true,
      },
    };

    return (
      <div className={classes.root}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={autosuggestInputProps}
          theme={{
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={options => (
            <Popper anchorEl={this.popperNode} open={Boolean(options.children)}>
              <Paper
                square
                {...options.containerProps}
                style={{ width: this.popperNode ? this.popperNode.clientWidth : null }}
              >
                {options.children}
              </Paper>
            </Popper>
          )}
        />
      </div>
    );
  }
}

IntegrationAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IntegrationAutosuggest);
