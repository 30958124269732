import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withNamespaces } from 'react-i18next';
import numeral from 'numeral';
import IntegrationAutosuggest from 'components/IntegrationAutosuggest';

const styles = theme => ({
  grid: {
    display: "flex",
    justifyContent: "center",
    padding: "5px"
  },
  gridButton: {
    margin: theme.spacing.unit,
    "& a": {
      textDecoration: "none",
    },
  },
  gridPaper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "150px",
    textAlign: "center",
    width: "100%",
  },
  heroContent: {
    width: "100%",
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 15px ${theme.spacing.unit * 6}px`,
  },
  main: {
    width: "100%",
  },
  spacer: {
    display: "block",
    minHeight: "30px"
  }
});

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfBoards: 0,
      nubmerOfArticles: 0,
      numberOfPushes: 0,
    };
  }

  componentDidMount() {
    axios.get('/api/v1/stats?category=whole_site')
      .then(response => {
        const json = response.data;

        this.setState({
          numberOfBoards: json["number_of_boards"],
          numberOfArticles: json["number_of_articles"],
          numberOfPushes: json["number_of_pushes"],
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const { classes, t } = this.props;
    const { numberOfBoards, numberOfArticles, numberOfPushes } = this.state;

    return (
      <main className={classes.main}>
        <div className={classes.heroContent}>
          <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
            {t('landingPage.heroContentHeader')}
          </Typography>
          <Typography variant="h6" align="center" color="textSecondary" paragraph>
            {t('landingPage.heroContent')}
          </Typography>

          <Grid container className={classes.container}>
            <Grid item xs />
            <Grid item xs={10} sm={6}>
              <IntegrationAutosuggest placeholder={t('app.integrationAutosuggest.placeholder')} />
            </Grid>
            <Grid item xs />
          </Grid>

          <div className={classes.spacer} />

          <Grid container className={classes.container}>
            <Grid item xs={12} className={classes.grid}>
              <Button
                component={Link}
                to='/rankings'
                variant="outlined"
                className={classes.gridButton}>
                <Typography variant="h6">
                  {t('landingPage.rankings')}
                </Typography>
              </Button>
            </Grid>
          </Grid>

          <div className={classes.spacer} />

          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4} className={classes.grid}>
              <Paper className={classes.gridPaper}>
                <Typography variant="h3" gutterBottom>
                  {numberOfBoards}
                </Typography>
                <Typography variant="h6">
                  {t('landingPage.stats.coveredBoards')}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.grid}>
              <Paper className={classes.gridPaper}>
                <Typography variant="h3" gutterBottom>
                  {numeral(numberOfArticles).format('0.00a')}
                </Typography>
                <Typography variant="h6">
                  {t('landingPage.stats.collectedArticles')}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.grid}>
              <Paper className={classes.gridPaper}>
                <Typography variant="h3" gutterBottom>
                  {numeral(numberOfPushes).format('0.00a')}
                </Typography>
                <Typography variant="h6">
                  {t('landingPage.stats.collectedPushes')}
                </Typography>
              </Paper>
            </Grid>
          </Grid>

          <div className={classes.spacer} />

          <Typography variant="caption" align="center" color="textSecondary" paragraph>
            {t('landingPage.disclaimer')}
          </Typography>
        </div>
      </main>
    );
  }
}

export default withStyles(styles)(withNamespaces()(LandingPage));
