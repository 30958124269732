import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Pagination from "material-ui-flat-pagination";
import { withNamespaces } from 'react-i18next';
import axios from 'axios';
import queryString from 'query-string';
import ErrorDisplay from 'components/ErrorDisplay';
import Loading from 'components/Loading';
import Heatmap from 'components/Heatmap';
import Activity from 'components/Activity';
import 'react-calendar-heatmap/dist/styles.css';

const styles = theme => ({
  activities: {
    margin: '10px auto',
    width: '100%',
  },
  pagination: {
    textAlign: 'center',
  },
  summaryGrid: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  summaryChips: {
    marginRight: 5,
    marginBottom: 5,
  },
});

class Activities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _heatmapSource: null,
      author: null,
      errors: null,
      heatmap: null,
      isActivityLoading: true,
      isHeatmapLoading: true,
      isSummaryLoading: true,
      pagination: {
        perPage: 5,
      }
    };
  }

  componentDidMount() {
    this.setState({ isSummaryLoading: true });
    this.getHeatmap();
    this.getActivity();
  }

  componentDidUpdate(prevProps) {
    const { search } = this.props.location;
    const { id } = this.props.match.params;

    if (id !== prevProps.match.params.id) {
      this.setState({ isSummaryLoading: true });
      this.getHeatmap();
      this.getActivity();
    } else if (search !== prevProps.location.search) {
      this.getActivity();
    }
  }

  componentWillUnmount() {
    this.cancelHeatmapRequest();
  }

  getActivity() {
    this.setState({ isActivityLoading: true });

    const { id } = this.props.match.params;

    axios.get('/api/v1/authors/' + id.toLowerCase() + this.props.location.search)
      .then(response => {
        this.setState({
          author: response.data,
          errors: null,
          isActivityLoading: false,
          isSummaryLoading: false,
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          errors: error,
          isActivityLoading: false,
          isSummaryLoading: false,
        });
      });
  }

  getHeatmap() {
    this.setState({ isHeatmapLoading: true });

    const { id } = this.props.match.params;

    this.cancelHeatmapRequest();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.setState({ _heatmapSource: source });

    axios.get('/api/v1/authors/' + id.toLowerCase() + '/heatmaps', {
      cancelToken: source.token
    })
    .then(response => {
      this.setState({
        heatmap: response.data,
        isHeatmapLoading: false,
      });
    })
    .catch(error => {
      if (axios.isCancel(error)) {
        // Request canceled. Do nothing...
      } else {
        console.log(error);
        this.setState({
          isHeatmapLoading: false,
        });
      }
    });
  }

  cancelHeatmapRequest = () => {
    const { _heatmapSource } = this.state;

    if (_heatmapSource !== null) { _heatmapSource.cancel('Heatmap request canceled due to new request...') }
  }

  handleHeatmapClick = (params) => {
    this.pushRequestPath(params);
  }

  handleCommentChipClick = () => {
    const { resources } = this.state.author.activities;
    const search = queryString.parse(this.props.location.search, { arrayFormat: 'bracket' });

    var newResources;
    if (resources.indexOf('comment') > -1) {
      newResources = resources.filter(e => e !== 'comment');
    } else {
      newResources = resources.concat('comment');
    }

    this.pushRequestPath({ ...search, page: 1, resources: newResources });
  }

  handlePaginationClick = (offset) => {
    const { activities: { start_time, end_time, resources } } = this.state.author;
    const { perPage } = this.state.pagination;
    const basicParams = {
      per_page: perPage,
      page: offset / perPage + 1,
      resources: resources
    }
    const params = (start_time && end_time) ? { ...basicParams,
      start_time: start_time,
      end_time: end_time } : basicParams;

    this.pushRequestPath(params);
  }

  pushRequestPath(params) {
    const { history } = this.props;
    const { id } = this.props.match.params;

    history.push('/authors/' + id.toLowerCase() + '/activities?' + queryString.stringify(params, {arrayFormat: 'bracket'}));
  }

  render() {
    const { classes } = this.props;
    const { errors, isActivityLoading, isHeatmapLoading, isSummaryLoading, pagination: { perPage } } = this.state;

    if (isSummaryLoading) { return <Loading />; }
    if (errors) { return null; }

    const { author, heatmap } = this.state;
    const { activities, summary, username } = author;
    const { current_page, entries, resources, total_count } = activities;
    const hasRestrictedContent = entries.some(entry => entry.board.restricted_content);
    const commentChipColor = resources.indexOf('comment') > -1 ? "secondary" : "default";

    return (
      <div>
        <div className={classes.summaryGrid}>
          <Chip
            className={classes.summaryChips}
            avatar={<Avatar>文</Avatar>}
            color="primary"
            label={summary.articles.count} />
          <Chip
            className={classes.summaryChips}
            avatar={<Avatar>推</Avatar>}
            color={commentChipColor}
            label={summary.pushes.plus_one_count}
            onClick={this.handleCommentChipClick} />
          <Chip
            className={classes.summaryChips}
            avatar={<Avatar>→</Avatar>}
            color={commentChipColor}
            label={summary.pushes.no_change_count}
            onClick={this.handleCommentChipClick} />
          <Chip
            className={classes.summaryChips}
            avatar={<Avatar>噓</Avatar>}
            color={commentChipColor}
            label={summary.pushes.minus_one_count}
            onClick={this.handleCommentChipClick} />
        </div>

        {
          isHeatmapLoading ? <Loading /> :
            heatmap === null ? <ErrorDisplay text="Can't display heatmap at the moment..." /> :
              <Heatmap
                handleClick={this.handleHeatmapClick}
                heatmap={heatmap}
              />
        }

        <div className={classes.activities}>
          {
            isActivityLoading ? <Loading /> :
            <div>
              {entries.map((activity, index) => <Activity
                key={activity.id}
                activity={activity}
                username={username}
                displayAd={!hasRestrictedContent &&
                  (index === 0 || index === (perPage - 1))}
              />)}
              <Pagination
                className={classes.pagination}
                limit={perPage}
                offset={perPage * (current_page - 1)}
                total={total_count}
                onClick={(e, offset) => this.handlePaginationClick(offset)}
                reduced={true}
              />
            </div>
          }
        </div>
      </div>
    );
  }
}

Activities.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withNamespaces()(Activities));
